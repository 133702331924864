import { useCallback } from 'react';
import { type Route } from 'type-route';

import { getCollectionIdBySlotId, isSlotIdValid } from '@common/helpers';
import { routes, useRoute } from '@navigation/router';

export const useMobileBackNavigation = () => {
  const route = useRoute() as Route<typeof routes.preview>;
  const slotId = route?.params?.slotId;

  const onBackBtnClick = useCallback(() => {
    if (!slotId || !isSlotIdValid(slotId)) {
      routes.home().push();
      return;
    }

    const collection = getCollectionIdBySlotId(slotId);
    if (!collection) {
      routes.home().push();
      return;
    }

    routes.collection({ collectionId: collection, slotId }).push();
  }, [slotId]);

  return onBackBtnClick;
};
