import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_TRAVEL: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'LGHY-As8N-e8aw',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/kärnten_tourismus_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'leDJ-DoZd-dQXX',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/St.-Anton-Arlberg_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'sXs6-eNnF-DfQC+oL1c-uqaC-2yJw+jjVf-dpIj-5j1b+4H6l-Hzy8-r1Y1',
    title: 'Battery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'KAvT-YprR-556z+JmhW-sVUP-nIui+QWKF-pH7Q-0gKl',
    title: 'Wipe Away & Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Pq9X-jGLE-19CK',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn-origin.yoc.com/AT/showroom/at/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'OZuU-MMAC-RyPo',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Folder3_Oebb.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/OEBB_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'vfYC-lRaz-ihJK',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'pWvz-fikD-tchx',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'wYTe-pml6-Iz3K',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Alxf-wY01-YZP3',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/seefestspiele-moerbisch-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/seefestspiele-moerbisch-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'gPFO-64Lu-J7Gr',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/gosaimaa_logo_fi.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'UhbC-ng5N-cfMA',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_MSC.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'F6uh-FIgV-hh2v',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_TUI.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Q1AX-elic-JeiL',
    title: 'Wipe Away',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_Discover-Airlines.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: '8OxT-LmPL-oReS',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/emirates-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/emirates-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'D4dJ-sQK0-nN0D',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/vrr-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/vrr-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'rEWr-KlE9-QdNQ',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn-origin.yoc.com/AT/showroom/at/background/ruefa_bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/ruefa_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'MYk0-ccNU-tCyU',
    title: 'Scroll-Reactive',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/tropicalisland-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/tropicalisland-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: '2Dx0-1lSY-uBvm',
    title: 'Mobile | Desktop',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn-origin.yoc.com/AT/showroom/at/background/obertauern-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/obertauern-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'Lzyo-2Wlj-HRru',
    title: 'Mobile | Desktop',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/salzburgersportwelt-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/salzburgersportwelt-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: '82Kv-2nhl-Ux3w',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'buK1-el9m-QP4B',
    title: 'Dynamic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '23Jr-ZxJI-nw67',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '0zNN-JuQv-tkjN',
    title: 'Classic',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'S943-pp9l-kEGb',
    title: 'Wipe Away & Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/schloss-hof-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/schloss-hof-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '44RB-3gOH-9JOh',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/Saalbach_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'CUGk-CkK3-oziq',
    title: 'Cube',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: '2KZE-iKgM-1Fqo',
    title: 'Vertical Video',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/dzt-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/DZT_Logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'ADnz-YDgE-qOoz+I2da-n8nB-6nwh',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/CH/showroom/ch/background/Club_Med.png',
    logoUrl: 'https://cdn.yoc.com/showroom/CH/Logo/logo-Club-Med.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'eAvt-Iws4-UOnA+n99F-7SMs-1wtc',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/easyjet-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/easyjet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '4H6l-Hzy8-r1Y1+R3Z0-DS2l-9wJv',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/Skin7_Saalbach.jpg',
    logoUrl: 'https://cdn.yoc.com/at/showroom/at/logo/easyjet-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: '9AuR-6AHX-ib4l+6GoT-zmv0-NPKd',
    title: 'Mobile',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SKIN,
    slotId: 'WsQE-Xgb6-TzkY',
    title: 'Desktop',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/Logo_AIDA.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'p9KL-3thu-Adrj',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/noe-werbung-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/noe-werbung-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 'YhNX-93jY-qizL',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_SITEBAR,
    slotId: 's59p-VVCv-cYxk',
    title: 'Animation',
    displayMode: 'desktop',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'ib1p-BxQh-dsMI',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'FOt0-OEiF-cOCR',
    title: 'Branded Player',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/at/showroom/at/background/enit-bg.png',
    logoUrl: 'https://cdn.yoc.com/showroom/AT/Logo/enit-logo.png',
  },
];
