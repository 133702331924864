import { isMobileOnly } from 'react-device-detect';

import { NON_MOBILE_CATEGORIES } from '@common/constants';
import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCollection } from '@common/types';

// Retrieves the default category for the 'collectionData'.
// 1. Sets 'defaultCategory' if it is specified in 'collectionData'.
// 2. If 'defaultCategory' is not set, checks if `YOC_MYSTERY_SCROLLER` exists in the collection and returns it.
// 3. If 'YOC_MYSTERY_SCROLLER' is not found, checks for the presence of the category 'YOC_MYSTERY_AD' and returns it.
// 4. If none of the above conditions are met, returns the category of the first item in ads.
export const getDefaultCategory = (collectionData: TCollection) => {
  const defaultCategory =
    collectionData.defaultCategory ??
    collectionData.ads.find((ad) => {
      return ad.category === E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER;
    })?.category ??
    collectionData.ads.find((ad) => {
      return ad.category === E_AD_CATEGORY_NAME.YOC_MYSTERY_AD;
    })?.category ??
    collectionData.ads[0]?.category;

  return defaultCategory;
};

export const getDefaultSlotId = (collectionData: TCollection, isMobile: boolean = false) => {
  // Use first found slotId from default category. If there is no default category, use first slotId from the list
  const defaultAd = collectionData.ads.find((item) => {
    const defaultCategory = getDefaultCategory(collectionData);
    const isDefaultCategory = Boolean(item.category === defaultCategory);
    const isNotDesktop = item.displayMode !== 'desktop';
    const isValidDisplayMode = isMobile ? isNotDesktop : !isMobileOnly || isNotDesktop;

    return isDefaultCategory && isValidDisplayMode;
  });

  return defaultAd?.slotId ?? collectionData.ads[0].slotId;
};

export const getCollectionDataAdsByDevice = (collectionAdsData: TCollection, isMobile: boolean): TCollection => {
  const filteredAds = isMobile
    ? collectionAdsData.ads.filter((item) => {
      return !NON_MOBILE_CATEGORIES.includes(item.category) && item.displayMode === 'mobile';
    })
    : collectionAdsData.ads;

  return {
    ...collectionAdsData,
    ads: filteredAds,
  };
};
