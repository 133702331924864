import { FunctionComponent, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { type Route } from 'type-route';

import { AVAILABLE_COUNTRIES } from '@common/constants';
import { classesBuilder } from '@common/helpers';
import { routes, useRoute } from '@navigation/router';

import style from './styles.module.scss';

const Navigation: FunctionComponent = () => {
  const route = useRoute();
  const [isOpen, setIsOpen] = useState(false);
  const [isCompactView, setIsCompactView] = useState(!isMobileOnly && window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      if (isCompactView !== (!isMobileOnly && window.innerWidth <= 1000)) {
        setIsCompactView(!isCompactView);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCompactView]);

  const toggleNavigation = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  };

  const activeCountry = AVAILABLE_COUNTRIES.find((countryId) => {
    return countryId === (route as Route<typeof routes.collection>).params.collectionId;
  })?.toUpperCase();

  return (
    <nav
      className={classesBuilder(style.nav, {
        [style.mobile]: isMobileOnly || isCompactView,
        [style['is-open']]: isOpen,
        [style['compact-view']]: isCompactView,
      })}
      data-testid="navigation"
      aria-label="Main navigation"
    >
      {(isMobileOnly || isCompactView) && (
        <button
          className={style['menu-button']}
          onClick={toggleNavigation}
          aria-expanded={isOpen}
          aria-controls="country-menu"
        >
          <span>{activeCountry || 'Menu'}</span>
        </button>
      )}

      <ul
        id="country-menu"
        role="menu"
      >
        {AVAILABLE_COUNTRIES.map((countryId) => {
          const isActive = countryId === activeCountry?.toLowerCase();

          return (
            <li
              key={countryId}
              onClick={toggleNavigation}
            >
              <a
                className={classesBuilder({ [style.active]: isActive })}
                {...routes.collection({ collectionId: countryId }).link}
              >
                {countryId.toUpperCase()}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
