import { CSSProperties } from 'react';
import axios from 'axios';

import {
  ADS_HOST_PRODUCTION,
  ADS_HOST_STAGING,
  AVAILABLE_COUNTRIES,
  DEFAULT_COUNTRY_CODE,
  SOURCE_OF_ASSET_TO_PRODUCT_NAME,
  TIME_ZONES,
} from '@common/constants';
import { E_AD_PRODUCT_KEY, type TAdData, type TAdDataBase, type TClassesBuilderArgument, type TCollectionId, type TSlotId } from '@common/types';
import PreviewScreen from '@components/PreviewScreen';
import { TLinkData } from '@components/ViewGallery/types';
import { ADS_COLLECTION } from '@configs/index';

import { E_COUNTRY_CODE, E_CUSTOM_CODE, E_PREVIEW_MODE } from './enums';

export const getCountryCode = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryCode = TIME_ZONES.find((zone) => {
    return zone.timeZone === userTimeZone;
  })?.code;

  if (countryCode) {
    return AVAILABLE_COUNTRIES.includes(countryCode) ? countryCode : DEFAULT_COUNTRY_CODE;
  }

  return DEFAULT_COUNTRY_CODE;
};

export const getBackendHost = (): string => {
  if (process.env.REACT_APP_OVERRIDE_HOST) {
    return process.env.REACT_APP_OVERRIDE_HOST;
  }

  return process.env.REACT_APP_BACKEND_HOST!;
};

export const fetchAdData = async (slotIds: string[]): Promise<TAdDataBase> => {
  // get data from production host
  const response = await axios.get(`//${ADS_HOST_PRODUCTION}/a/ad-preview-type`, {
    params: { slotId: slotIds },
  });

  if (response.status !== 200) {
    throw response;
  }

  if (process.env.ADS_ENVIRONMENT !== 'production') {
    // check if we have a slotIds with null value (not found)
    const nullSlotIds = Object.keys(response.data).filter((item) => {
      return response.data[item] === null;
    });

    if (nullSlotIds.length === 0) {
      return response.data;
    }

    // get data from staging host
    const responseStaging = await axios.get(`//${ADS_HOST_STAGING}/a/ad-preview-type`, {
      params: { slotId: nullSlotIds },
    });

    if (responseStaging.status !== 200) {
      throw responseStaging;
    }

    return { ...response.data, ...responseStaging.data };
  }

  return response.data;
};

export const isSlotIdValid = (slotId: string): boolean => {
  return /^(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4}$/.test(slotId);
};

// slot id is represented as 4characters - 4characters - 4characters -4characters
// following pattern covers multiple slotids joined with + {slotid1}+{slotid2}+...
export const slotIdPattern = /^(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4}(?:\+(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4})*$/;

export const validateSlotId = (slotId: string): boolean => {
  return slotIdPattern.test(slotId);
};

export const isSlotIdMultiple = (slotId: string | undefined): boolean => {
  return typeof slotId === 'string' && /^(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4}\+(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4}(?:\+(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4})*$/.test(slotId);
};

export const isSlotIdUrlValid = (slotId: string): boolean => {
  return /^(?:[\dA-Za-z]{4}-){2}[\dA-Za-z]{4}$/.test(slotId);
};

const multiSlotIdSeparator = '+';
export const getSlotIdsFromString = (slotIdString: string): string[] => {
  return slotIdString.split(multiSlotIdSeparator).filter((slotId) => {
    return isSlotIdUrlValid(slotId);
  });
};
export const splitSlotIdsFromUrl = (slotIdText: string): string[] => {
  return slotIdText.split(multiSlotIdSeparator).filter((slotId) => {
    return slotId !== '';
  });
};

export const getAdCategoryBySlotId = (slotId: string) => {
  for (const key in ADS_COLLECTION) {
    const collection = ADS_COLLECTION[key as E_COUNTRY_CODE | E_CUSTOM_CODE];
    for (const ad of collection.ads) {
      if (ad.slotId === slotId) {
        return ad.category;
      }
    }
  }
  return undefined;
};

export const getCollectionIdBySlotId = (slotId: string): TCollectionId | null => {
  return Object.keys(ADS_COLLECTION)
    .filter((id) => {
      return id !== E_CUSTOM_CODE.YUDS;
    })
    .find((id) => {
      return ADS_COLLECTION[id as TCollectionId].ads.some((ad) => {
        return ad.slotId === slotId;
      });
    }) as TCollectionId | null;
};

export const getProductNameFromAdData = (adData: TAdData): string | undefined => {
  switch (adData.productKey) {
    case E_AD_PRODUCT_KEY.TPA:
      return adData.sourceOfAsset ? SOURCE_OF_ASSET_TO_PRODUCT_NAME[adData.sourceOfAsset] : undefined;
    default:
      return adData.productName ? adData.productName : undefined;
  }
};

export const isDesktopOnly = (adData: TAdData): boolean => {
  return (
    adData.restrictShowcasePreview === 'desktop-only' ||
    (adData.previewType === E_PREVIEW_MODE.DESKTOP && adData.productKey === E_AD_PRODUCT_KEY.YS) ||
    adData.previewType === E_PREVIEW_MODE.DESKTOP
  );
};

export const isMobileOnly = (adData: TAdData): boolean => {
  return adData.restrictShowcasePreview === 'mobile-only';
};

export const addSuffixToPreviewUrl = (url: string, extraParams: string[] = []) => {
  if (url && url !== '') {
    let queryString = '';
    extraParams.forEach((param) => {
      queryString += `${(url.includes('?') || queryString.includes('?')) ? '&' : '?'}${param}`;
    });
    return `${url}${queryString}`;
  }
  return '';
};

export const filterAdDataBySlotIds = (
  adsDataCollection: TAdDataBase | undefined,
  slotIds: TSlotId[] | null
): TAdDataBase => {
  if (adsDataCollection === null || adsDataCollection === undefined || slotIds === null) {
    return null;
  }
  return Object.keys(adsDataCollection)
    .filter((key) => {
      return slotIds.includes(key);
    })
    .reduce((result: { [key: string]: TAdData }, key) => {
      result[key] = adsDataCollection[key];
      return result;
    }, {});
};

export const getMultiFramePreviewHelper = (item: TLinkData, currentSlideIndex?: number, activeSlideIndex?: number) => {
  const isForGallery = activeSlideIndex !== undefined && currentSlideIndex !== undefined;
  const isActiveSlide = currentSlideIndex === activeSlideIndex;

  const getPreviewUrl = (mode: E_PREVIEW_MODE, extraParams?: string[]) => {
    const urlData = item[mode];
    if (!urlData) {
      return '';
    }
    return isActiveSlide ? addSuffixToPreviewUrl(urlData.fullUrl, extraParams ? extraParams : []) : urlData.baseUrl;
  };

  const renderPreviewScreen = (mode: E_PREVIEW_MODE, scale: string, styleClass: string, extraParams?: string[]) => {
    return (
      <div
        style={{ '--preview-scale': scale } as CSSProperties}
        className={styleClass}
      >
        <PreviewScreen
          mode={mode}
          url={getPreviewUrl(mode, extraParams)}
        />
      </div>
    );
  };

  return {
    isForGallery,
    renderPreviewScreen,
  };
};

// Takes any number of arguments of different types (strings and objects)
// and returns a string with the CSS class names that should be applied to an element.
// Examples:
// classesBuilder('foo', 'bar'); // => 'foo bar'
// classesBuilder('foo', { bar: true }); // => 'foo bar'
// classesBuilder({ 'foo-bar': true }); // => 'foo-bar'
// classesBuilder({ 'foo-bar': false }); // => ''
// classesBuilder({ foo: true }, { bar: true }); // => 'foo bar'
// classesBuilder({ foo: true, bar: true }); // => 'foo bar'
export const classesBuilder = (...args: TClassesBuilderArgument[]): string => {
  return args
    .flatMap((arg) => {
      if (typeof arg === 'string') {
        return arg;
      }
      if (typeof arg === 'object' && arg !== null) {
        return Object.keys(arg).filter((className) => {
          return arg[className];
        });
      }
      return [];
    })
    .join(' ');
};
