import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_FI: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'ounp-wfib-CCwg',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Ravintoraisio_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: '9Jxb-Lfzh-0KRC',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/mtv-logotype.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'XYAr-I34j-TyhE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/gigantti_fi.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '0Fj7-aPVu-186w',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Djk3-4HB6-uZVY',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'NTDy-MHbw-DpJj',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/ifolor_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3SWx-2ju4-WaYI',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/ifolor_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'nO8G-Nyrh-C46p',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/HBO_Max_Logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'agMl-jaKz-lGq9',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/novart_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'mkj3-sqOW-DdsM',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/novart_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'tpnu-npjh-HROJ',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/novart_logo.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'YzGC-NtcP-2FYQ',
    title: 'Swipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/orion_oyj-n_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ylxA-x8OP-mHpH',
    title: 'Hotspot',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/DNA_Oyj_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '2mcs-R6tH-rXyN',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Sinebrychoff_Garage.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'qEAD-ratL-bkEZ',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Froneri_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'EGsu-WREw-xESY',
    title: 'Video Integration',
    displayMode: 'desktop',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/DE/Logo/ICE.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'FLET-F0X1-LFXk',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Samsung.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'P3IS-T5TB-Zjyk',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/mtv-logotype.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Kotf-76Qs-HURZ',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/logo-picadeli.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'TXLi-HLO4-m5cR',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/mtv-logotype.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Gaqs-12D7-u9P5',
    title: 'Static',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/DNA_Oyj_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'f6Em-FIPE-yQl2',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'mKJ3-Y2f8-U1NA',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Telia_Company_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'GFIs-qYuQ-RyDg',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/apollo_logo.svg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'HnMT-mcJD-utS4',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/Sinebrychoff_Garage.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'kQXF-EYxk-3o0x',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: '',
    logoUrl: 'https://cdn.yoc.com/showroom/FI/Logo/HBO_Max_Logo.svg',
  },
];
