import { E_PREVIEW_MODE, E_PREVIEW_THEME, TPreviewTheme } from '@common/enums';
import { getBackendHost } from '@common/helpers';
import { E_AD_PRODUCT_KEY, TAdData } from '@common/types';

/*
 * HOST = cb.advertising.gallery
 * HOST = cb-staging.advertising.gallery
 *
 * {HOST}/preview/desktop-showroom/{slotId}
 * {HOST}/preview/desktop-showroom/{slotId}?yoc_preview // removes CMP from target page
 * {HOST}/preview/desktop-showroom/{slotId}?effect=sitebar // NOT possible
 * {HOST}/preview/desktop-showroom/{slotId}?product=ys
 * {HOST}/preview/desktop-showroom/{slotId}?product=uaf&effect=sitebar
 * {HOST}/preview/mobile/{slotId}
 * {HOST}/preview/mobile/{slotId}?yoc_preview
 *
 * {HOST}/preview/neutral-desktop/{slotId}
 * {HOST}/preview/neutral-desktop/{slotId}?yoc_preview
 * {HOST}/preview/neutral-mobile/{slotId}
 * {HOST}/preview/neutral-mobile/{slotId}?yoc_preview
 * */

export const getPreviewTemplateUrl = (previewMode: E_PREVIEW_MODE, theme: TPreviewTheme = E_PREVIEW_THEME.YOC) => {
  let templateName = previewMode.toString();

  switch (theme) {
    case E_PREVIEW_THEME.NEUTRAL:
      templateName = `${E_PREVIEW_THEME.NEUTRAL}-${previewMode}`;
      break;
    default:
      // TODO: Refactor templates to avoid using `desktop-showroom` name. Use `desktop` instead
      if (previewMode === E_PREVIEW_MODE.DESKTOP) {
        templateName = 'desktop-showroom';
      }
  }

  return `https://${getBackendHost()}/preview/${templateName}`; // TODO: Integration Test requires protocol
};

/* eslint-disable-next-line */
export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeoutID: number;

  if (!Number.isInteger(wait)) {
    wait = 300;
  }

  // conversion through `any` necessary as it won't satisfy criteria otherwise
  /* eslint-disable-next-line */
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);

    timeoutID = window.setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const generateAdPreviewUrl = (
  previewMode: E_PREVIEW_MODE,
  adData: TAdData,
  slotId: string,
  isGallery: boolean = false
) => {
  const src = `${getPreviewTemplateUrl(previewMode, adData?.previewWebsiteLayout)}`;
  let fullUrl = `${src}/${slotId}`;
  // Exceptional case: we need product=yisv on YISV iframes in gallery view
  // in order to display VAST related layout.
  if (adData?.productKey === E_AD_PRODUCT_KEY.YISV && isGallery) {
    fullUrl = `${fullUrl}?product=${adData.productKey.toLocaleLowerCase()}`;
  }
  return {
    baseUrl: src,
    fullUrl,
  };
};
