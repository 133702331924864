export const getTcfAPIHandlerCode = () => {
  return `
    window.addEventListener('message', function(event) {
      // Define the allowed origins
      const allowedOrigins = [
        'https://localhost:8121',
        'https://cb-staging.advertising.gallery',
        'https://cb.advertising.gallery'
      ];
      if (allowedOrigins.includes(event.origin) && event.data.type === 'IS_TCF_AVAILABLE') {
          event.source.postMessage({
            type: 'CMP_LOADED',
            data: {}
          }, event.origin);
      }
    });
  `;
};
