import { createRouter, defineRoute, noMatch, param, type ValueSerializer } from 'type-route';

import { E_COUNTRY_CODE, E_CUSTOM_CODE } from '@common/enums';
import { validateSlotId } from '@common/helpers';
import { TCollectionId, TSlotId } from '@common/types';
import { E_COOKIE_ACTION_TYPE, TCookieActionType } from '@pages/CookiePage/types';

export const valueSerialize = <T extends string>(isValidRaw: (raw: T) => boolean): ValueSerializer<T> => {
  return {
    parse: (raw: string) => {
      if (!isValidRaw(raw as T)) {
        return noMatch;
      }

      return raw as T;
    },
    stringify: (value) => {
      return value;
    },
  };
};

export const collectionIdType: ValueSerializer<TCollectionId> = valueSerialize((raw) => {
  return (Object.values({ ...E_COUNTRY_CODE, ...E_CUSTOM_CODE }) as string[]).includes(raw);
});

export const cookieType: ValueSerializer<TCookieActionType> = valueSerialize((raw) => {
  return (Object.values(E_COOKIE_ACTION_TYPE) as string[]).includes(raw);
});

const slotIdType: ValueSerializer<TSlotId> = valueSerialize((raw) => {
  return validateSlotId(raw);
});

export const { RouteProvider, useRoute, routes, session } = createRouter({
  // NOTE: The order is important! Be careful with the order of the routes.
  home: defineRoute('/'),
  collection: defineRoute(
    {
      collectionId: param.path.ofType(collectionIdType),
      slotId: param.path.optional.string,
    },
    (p) => {
      return `/${p.collectionId}/${p.slotId}`;
    }
  ),
  cookie: defineRoute(
    {
      actionType: param.path.ofType(cookieType),
    },
    (p) => {
      return `/cookie/${p.actionType}`;
    }
  ),
  websitePreview: defineRoute(
    {
      slotId: param.path.optional.ofType(slotIdType),
    },
    (p) => {
      return `/website/preview/${p.slotId}`;
    }
  ),
  preview: defineRoute(
    // e.g. /vN8A-mr0l-ODWk/mobile?product=yma&effect=inlineToSticky
    // `param.query` are not working anymore?
    {
      slotId: param.path.ofType(slotIdType),
      mode: param.path.optional.string, // mobile | desktop
      product: param.query.optional.string,
      effect: param.query.optional.string,
    },
    (p) => {
      return `/${p.slotId}/${p.mode}`;
    }
  ),
});
