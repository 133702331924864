import { FunctionComponent, memo, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { type Route } from 'type-route';

import { E_PREVIEW_MODE, E_PREVIEW_THEME } from '@common/enums';
import { getMultiFramePreviewHelper } from '@common/helpers';
import { useDmexco, useRefDimensions } from '@common/hooks';
import { useGalleryStore, useThemeStore } from '@common/stores';
import Gallery from '@components/Gallery';
import QRCodeLink from '@components/QRCodeLink';
import { useAdSlidesData } from '@components/ViewGallery/hooks';
import { routes, useRoute } from '@navigation/router';

import type { TGalleryViewProps, TLinkData } from './types';

import styles from './styles.module.scss';

const GalleryView: FunctionComponent<TGalleryViewProps> = (props) => {
  const { adsData } = props;

  // if it's a `Preview` page, check `mode` from URL params
  const {
    name: routeName,
    params: { slotId, mode: paramsMode },
  } = useRoute() as Route<typeof routes.preview>;

  // THEME
  const theme = useThemeStore((state) => {
    return state.theme;
  });

  const isDMEXCOMode = useDmexco(); // https://yocmobile.atlassian.net/browse/DC-328
  const isPortrait = isDMEXCOMode;

  const { ref: containerRef } = useRefDimensions<HTMLDivElement>();

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('preview-mobile');
      document.body.classList.remove('mobile');
    }

    return () => {
      if (!isMobile) {
        document.body.classList.remove('preview-mobile');
      }
    };
  }, []);

  // URL Management
  useEffect(() => {
    // remove paramsMode if it was provided for `preview` route
    if (routeName === 'preview' && slotId && paramsMode) {
      routes.preview({ slotId }).replace();
    }
  }, [paramsMode, routeName, slotId]);

  // Gallery
  const { activeSlideNumber } = useGalleryStore();
  // new
  const slidesData: TLinkData[] = useAdSlidesData(adsData);
  const activeSlideData = slidesData[activeSlideNumber];

  const slides = slidesData.map((item, index) => {
    const { isForGallery, renderPreviewScreen } = getMultiFramePreviewHelper(item, index, activeSlideNumber);
    return (
      <div
        className={styles.holder}
        key={item.name + index}
      >
        <div className="frame-container">
          {item[E_PREVIEW_MODE.MOBILE] &&
            renderPreviewScreen(
              E_PREVIEW_MODE.MOBILE,
              // If it's dmexco, if it's mobile only make mobile frame bigger,
              // if its mobile and desktop make it smaller.
              !isDMEXCOMode ? '0.84' : item[E_PREVIEW_MODE.DESKTOP] !== null ? '1.1' : '1.84',
              styles['crop-mobile'],
              ['yoc_preview', ...(isDMEXCOMode ? ['prevent_leaving'] : [])]
            )}
          {item[E_PREVIEW_MODE.DESKTOP] &&
            renderPreviewScreen(E_PREVIEW_MODE.DESKTOP, isDMEXCOMode ? '0.465' : '0.48', styles['crop-desktop'], [
              'yoc_preview',
              ...(isDMEXCOMode ? ['prevent_leaving'] : []),
            ])}
        </div>
        {isForGallery && <span className={styles.title}>{item.name}</span>}
      </div>
    );
  });

  if (slides.length === 0) {
    return (
      <div className="main">
        <h1>NO AD/EFFECT FOUND</h1>
        <p>Unfortunately, there is no Effect or Ad to display 🤧</p>
      </div>
    );
  }

  return (
    <>
      <div className={`container ${isPortrait ? 'v-space' : 'h-space'}`.trim()}>
        <div
          className="ref-container"
          ref={containerRef}
        >
          <Gallery
            key={slotId}
            ref={containerRef}
            slides={slides}
            isPortrait={isPortrait}
          />
        </div>
      </div>

      {activeSlideData?.[E_PREVIEW_MODE.DESKTOP] && !isDMEXCOMode && (
        <div className="switcher">
          <button
            className="button fullscreen"
            onClick={(event) => {
              event.preventDefault();
              window.open(activeSlideData[E_PREVIEW_MODE.DESKTOP]?.fullUrl, '_blank');
            }}
            data-testid="fullscreenButton"
          >
            {theme === E_PREVIEW_THEME.YOC ? 'Demo' : 'Fullscreen'}
          </button>
        </div>
      )}

      {activeSlideData?.[E_PREVIEW_MODE.MOBILE] && (
        // TODO: CHECk IT - hide QRCode if there is no ad data with the corresponding slotid.
        // slidesData[activeSlideNumber]?.[E_PREVIEW_MODE.MOBILE]?.baseUrl !== '' && (
        <QRCodeLink
          className="qr-link"
          value={activeSlideData[E_PREVIEW_MODE.MOBILE]?.fullUrl ?? ''}
          size={isDMEXCOMode ? 120 : 90}
        />
      )}
    </>
  );
};

export default memo(GalleryView);
