import { type FunctionComponent, memo, useEffect } from 'react';

import { classesBuilder } from '@common/helpers';
import { setModalSlotId } from '@common/stores';
import type { TCSSCustomProperty } from '@common/types';

import { useGroupedAds } from './hooks';
import type { TMenuProps } from './types';

import style from './styles.module.scss';

const Menu: FunctionComponent<TMenuProps> = (props) => {
  const { creatives, onCreativeChange, activeSlotId, isMobile = false } = props;

  // Group SlotIds by categories
  const { groupedAds, activeCategory, onToggleCategory } = useGroupedAds(
    creatives,
    onCreativeChange,
    activeSlotId,
    isMobile,
  );

  // Preload images
  useEffect(() => {
    if (groupedAds) {
      groupedAds.forEach(({ ads }) => {
        if (ads && Array.isArray(ads) && ads.length) {
          ads.forEach((ad) => {
            if (ad.backgroundUrl) {
              new Image().src = ad.backgroundUrl;
            }

            if (ad.logoUrl) {
              new Image().src = ad.logoUrl;
            }
          });
        }
      });
    }
  }, [groupedAds]);

  return (
    <ul
      data-testid="menu"
      className={classesBuilder(style.menu, {
        [style.mobile]: isMobile,
      })}
    >
      {groupedAds?.map((group) => {
        return (
          <li
            key={group.categoryName}
            className={classesBuilder({
              [style.expanded]: activeCategory === group.categoryName,
            })}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onToggleCategory(group.categoryName);
            }}
          >
            {
              <>
                <div className={style.title}>
                  {group.categoryName}
                </div>
                <div className={style.wrapper}>
                  <ul className={style.list}>
                    {group.ads.map((ad) => {
                      const itemStyle: Partial<TCSSCustomProperty> = {};
                      if (ad.backgroundUrl) {
                        itemStyle['--background-url'] = `url(${ad.backgroundUrl})`;
                      }
                      if (ad.logoUrl) {
                        itemStyle['--logo-url'] = `url(${ad.logoUrl})`;
                      }
                      return (
                        <li
                          key={ad.slotId}
                          className={classesBuilder(style.item, {
                            [style.active]: activeSlotId === ad.slotId,
                          })}
                          style={itemStyle}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setModalSlotId(ad.slotId); // why?
                            onCreativeChange( // why?
                              ad.slotId,
                              ad.category,
                              ad.displayMode === 'desktop'
                            ); // move to separate handler?
                          }}
                          data-slotid={ad.slotId} // for integration tests
                        >
                          {ad.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            }
          </li>
        );
      })}
    </ul>
  );
};

export default memo(Menu);
